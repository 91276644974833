<template>
  <div>
    <v-card elevation="0">
            <v-toolbar color="#ef6b01" rounded="0" elevation="5" class="fixed-bar">
            <v-btn icon dark @click="atras"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
            <v-card-title style="color:white" class="text-truncate text-center">Confirmar Orden</v-card-title>
        </v-toolbar>
        <v-card-text class="text-center mb-15">
            <v-row style="justify-content: center;" class="mt-1">
                <v-col>
                    <font-awesome-icon style="font-size:38px;color: #ff9800;" icon="cart-shopping"/>
                    <p class="text-h5 font-weight-black mt-2">Productos</p>
                </v-col>
                <v-col>
                    <v-card elevation="0" color="orange" dark>
                        <v-card-text >
                             <span style="font-size:15px">Cant. Items: {{carrito.length}}</span>
                             <p v-if="precioConIva" style="font-size:25px" class="font-weight-black pa-0 ma-0">{{Math.round(totalSinIva) | toCurrency}}<span style="font-size: 13px;"> más IVA</span></p>
                             <p v-if="!precioConIva" style="font-size:25px" class="font-weight-black pa-0 ma-0">{{Math.round(total) | toCurrency}}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row style="justify-content: center;" class="mt-1">
                <v-col>
                    <v-icon color="orange" style="font-size:40px">mdi-send</v-icon>
                    <p class="text-h5 font-weight-black mt-2">Tipo de Envío</p>
                </v-col>
                <v-col>
                    <v-card elevation="0" dark :style="(datosCompra.tipoEnvio=='Envio' && datosCompra.costoFlete<=0)?'background-color: #f3c700':'background-color:#ff9800'">
                        <v-card-text v-if="datosCompra.tipoEnvio=='Envio' && datosCompra.costoFlete==null">
                             <p style="font-size:25px;margin:0px" class="mt-2 mb-2 font-weight-black">Envío</p>
                        </v-card-text>
                        <v-card-text v-else>
                             <span v-if="datosCompra.tipoEnvio=='Envio'" :style="(datosCompra.costoFlete<=0)?'color:black':''" style="font-size:15px">Envío</span>
                             <b v-if="datosCompra.tipoEnvio=='Retiro'" style="font-size:25px">{{datosCompra.tipoEnvio}}</b>
                             <p v-if="datosCompra.tipoEnvio=='Envio' && datosCompra.costoFlete>0" style="font-size:25px" class="font-weight-black pa-0 ma-0">{{(precioConIva)?(datosCompra.costoFlete/(1.21)):datosCompra.costoFlete | toCurrency}} <span v-if="precioConIva" style="font-size: 13px;"> más IVA</span></p>
                             <p v-if="datosCompra.tipoEnvio=='Envio' && datosCompra.costoFlete<=0" style="font-size:25px" class="font-weight-black pa-0 ma-0" :style="(datosCompra.costoFlete<=0)?'color:black':''">Gratis</p>
                             <!-- <p v-if="datosCompra.tipoEnvio=='Retiro'" style="font-size:25px" class="font-weight-black pa-0 ma-0">$0.00</p> -->
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row style="justify-content: center;" class="mt-1">
                <v-col>
                    <p class="mt-2 letra" style="color:orange;font-weight: 700;font-size: large;">Total contado</p>
                </v-col>
                <v-col>
                    <p class="mt-2 letra" style="color:orange;font-weight: 700;font-size: large;">{{Math.round(totalContado) | toCurrency}}</p>
                </v-col>
            </v-row>
            <v-row class="mt-5" style="align-items: center;">
                <v-col cols="2" style="display: grid;    align-items: center;">
                    <v-icon color="orange" style="font-size:36px">mdi-text-box</v-icon>
                </v-col>
                <v-col cols="10" style="text-align:initial" @click="detallecompra()">
                    <p class="mb-0 mt-0 p-0" style="font-size:20px">Ver detalles</p>
                </v-col>
            </v-row>
            <v-divider class="mt-3 ml-3" style="border-top: ridge;"></v-divider>
            <v-row class="mt-5" style="align-items: center;">
                <v-col cols="2">
                    <!-- <v-icon color="orange" style="font-size:36px">mdi-text-box</v-icon> -->
                    <font-awesome-icon style="color:#ff9800;font-size: 34px;" icon="receipt" />
                </v-col>
                <v-col cols="10" style="text-align:initial">
                    <!-- <p class="mb-0 mt-0 p-0" style="font-size:20px">Orden de compras</p> -->
                    <v-text-field style="font-size:20px" clearable clear-icon="fas fa-times" v-model="textoOrdenDeCompras" label="Orden de compras" color="orange"></v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mt-3 ml-3" style="border-top: ridge;"></v-divider>
            <v-row v-if="this.datosCompra.tipoEnvio=='Envio'" class="mt-5" style="align-items: center;">
                <v-col cols="2" style="display: grid;    align-items: center;">
                    <v-icon color="orange" style="font-size:36px">mdi-map-marker</v-icon>
                </v-col>
                <v-col cols="10" style="text-align:initial">
                    <p class="mb-0 mt-0 p-0" style="font-size:20px">Enviar a</p>
                    <p class="mb-0 mt-0 p-0 text-subtitle-2" style="font-size:20px" v-if="this.cliente">{{`${cliente.DOM} (${cliente.CP}) ${cliente.LOCALIDAD} ${cliente.PROVINCIA}`}}</p>
                </v-col>
            </v-row>
            <v-divider v-if="this.datosCompra.tipoEnvio=='Envio'" class="mt-3 ml-3" style="border-top: ridge;"></v-divider>
            <v-row v-if="this.datosCompra.tipoEnvio=='Retiro'" class="mt-5" style="align-items: center;">
                <v-col cols="2" style="display: grid;    align-items: center;">
                    <v-icon color="orange" style="font-size:36px">mdi-clock-outline</v-icon>
                </v-col>
                <v-col cols="10" style="text-align:initial">
                    <p class="mb-0 mt-0 p-0" style="font-size:20px">Horario</p>
                    <p class="mb-0 mt-0 p-0 text-subtitle-2" style="font-size:20px">{{this.datosCompra.horarioRetiro}}</p>
                </v-col>
            </v-row>
            <v-divider v-if="this.datosCompra.tipoEnvio=='Retiro'" class="mt-3 ml-3" style="border-top: ridge;"></v-divider>
            <v-row class="mt-5" style="align-items: center;">
                <v-col cols="2" style="display: grid;    align-items: center;">
                    <v-icon color="orange" style="font-size:36px">mdi-cash-multiple</v-icon>
                    <!-- <font-awesome-icon style="color:#ff9800;font-size: 34px; margin-left: 13px;" icon="money-bills" /> -->
                </v-col>
                <v-col cols="10" style="text-align:initial">
                    <p class="mb-0 mt-0 p-0" style="font-size:20px">Forma de pago</p>
                    <p class="mb-0 mt-0 p-0 text-subtitle-2" style="font-size:20px">{{this.datosCompra.medioPago}}</p>
                </v-col>
            </v-row>
            <v-divider class="mt-3 ml-3" style="border-top: ridge;"></v-divider>
            <v-row class="mt-5" style="align-items: center;">
                <v-col cols="2" style="display: grid;    align-items: center;">
                    <v-icon color="orange" style="font-size:36px">mdi-credit-card-outline</v-icon>
                </v-col>
                <v-col cols="10" style="text-align:initial">
                    <p class="mb-0 mt-0 p-0" style="font-size:20px">Datos de facturación</p>
                    <p class="mb-0 mt-0 p-0 text-subtitle-2" style="font-size:20px" v-if="cliente">{{cliente.RZ}}</p>
                    <p class="mb-0 mt-0 p-0 text-subtitle-2" style="font-size:20px" v-if="cliente">{{cliente.CUIT}}</p>
                </v-col>
            </v-row>
            <v-divider class="mt-3 ml-3" style="border-top: ridge;"></v-divider>
            <v-row class="mt-5" style="align-items: center;">
                <v-col cols="2" style="display: grid;    align-items: center;">
                    <v-icon color="orange" style="font-size:36px">mdi-message-processing</v-icon>
                </v-col>
                <v-col cols="10" style="text-align:initial">
                    <!-- <p class="mb-0 mt-0 p-0" style="font-size:20px">Observaciones</p> -->
                    <v-text-field style="font-size:20px" clearable clear-icon="fas fa-times" v-model="observaciones" label="Observaciones" color="orange"></v-text-field>
                </v-col>
            </v-row>
            <!-- <v-divider class="mt-3 ml-3" style="border-top: ridge;"></v-divider> -->
        </v-card-text>
        <!--FOOTER---------------->
        <v-footer color="#ef6b01" padless fixed outlined class="mt-5" style="height: 57px;" rounded="0">
            <v-row justify="center" no-gutters>
                <v-col cols="4">
                    <v-btn small style="height: 54px;font-size: initial;" color="white" text elevation="0" @click="irVentas">CANCELAR</v-btn>
                </v-col>
                <v-col cols="4" class="text-center">
                    <v-btn style="bottom: 23px;" fab color="#ef6b01" elevation="20" @click="irVentas" class="my-2"><font-awesome-icon style="font-size:25px;color: white" icon="plus"/></v-btn>
                </v-col>
                <v-col cols="4" class="text-end">
                    <v-btn small style="height: 54px;font-size: initial;" color="white" text elevation="0" @click="confirmar">CONFIRMAR</v-btn>
                </v-col>
            </v-row>
        </v-footer>
        <!-- SNACKBAR -->
        <v-snackbar v-model="snackbar" class="mb-15" :color="snackbarColor" text outlined>
            {{ snackbarMsge }}
            <template v-slot:action="{ attrs }">
                <v-btn icon :color="snackbarColor" text v-bind="attrs" @click="snackbar = false"><v-icon>{{snackbarIcon}}</v-icon></v-btn>
            </template>
        </v-snackbar>
        <!-- ----CARGANDO------- -->
        <v-overlay :value="cargando">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
            ></v-progress-circular>
        </v-overlay>
        <!-- DIALOG PRODUCTOS CON ERROR -->
        <v-dialog  v-model="dialogError" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card loader-height="8px">
                <v-toolbar color="#ef6b01">
                    <v-btn icon dark @click="dialogError = false"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
                    <v-card-title style="color:white" class="text-truncate">Productos con inconsistencias</v-card-title>
                </v-toolbar>
                <div style="margin-inline: 15px;" class="mt-5 mb-12">
                    <v-row class="justify-center">
                        <h2 ><v-icon style="color:#ef6b01;font-size: 65px;">mdi-emoticon-frown-outline</v-icon> </h2>
                    </v-row>
                    <v-row class="justify-center mt-5">
                        <h3>Hay una inconsistencia en datos de artículos</h3>
                    </v-row>
                    <v-toolbar color="#ef6b01" class="mt-10">
                        <p style="color:white;padding:0px;margin: 0px;" class="text-truncate">PRODUCTOS CORREGIDOS</p>
                    </v-toolbar>
                    <!-- LISTA ERROR EN STOCK  -->
                    <v-list three-line v-if="this.sinStock.length>0" elevation="0">
                        <template v-for="(item, index) in sinStock" >
                            <v-list-item :key="item.cod_art" class="mb-3">
                            <v-list-item-avatar :rounded="true" size="80px" horizontal>
                                <Imagen v-if="item.imagen" :src="item.imagen"/> 
                            </v-list-item-avatar>
                            <v-list-item-content>
                                {{item.desc_cliente}}
                                <v-list-item-subtitle><span><b>Código: </b><b style="color:orange;font-size:17px">{{item.cod_art}}</b></span></v-list-item-subtitle>
                                <!-- <v-list-item-subtitle v-if="!precioConIva && usuario.muestra_lista==1"><span><b>Precio: </b><b style="color:orange;font-size:17px">{{Math.round(item.pr_final) | toCurrency}}</b></span></v-list-item-subtitle>
                                <v-list-item-subtitle v-if="precioConIva && usuario.muestra_lista==1"><span><b>Precio: </b><b style="color:orange;font-size:17px">{{Math.round(item.pr_vta) | toCurrency}}</b></span> <span style="color:orange;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                                <v-list-item-subtitle v-if="!precioConIva && item.cant_empaq!=1 && usuario.muestra_lista==1"><span><b>Precio por empaque: </b><b style="color:orange;font-size:17px">{{Math.round(item.pr_final*item.cant_empaq) | toCurrency}}</b></span></v-list-item-subtitle>
                                <v-list-item-subtitle v-if="precioConIva && item.cant_empaq!=1 && usuario.muestra_lista==1"><span><b>Precio por empaque: </b><b style="color:orange;font-size:17px">{{Math.round(item.pr_vta*item.cant_empaq) | toCurrency}}</b></span><span style="color:orange;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle> -->
                                <v-row class="mt-3" style="margin-left: auto;">
                                <!-- <p class="mt-2" style="color:red">Artículo con menor cantidad a la seleccionada</p> -->
                                    <b  style="font-size: 17px;color:orange;">Stock actual disponible: {{descEmpaque(item)}}</b>
                                </v-row>
                            </v-list-item-content>
                            </v-list-item>
                            <v-divider :key="index" :inset="item.inset"></v-divider>
                        </template>
                    </v-list>
                    <!-- LISTA ERROR EN PRECIO  -->
                    <v-list three-line v-if="this.nuevoPrecio.length>0" elevation="0">
                        <template v-for="(item, index) in nuevoPrecio" >
                            <v-list-item :key="item.cod_art" class="mb-3">
                            <v-list-item-avatar :rounded="true" size="80px" horizontal>
                                <Imagen v-if="item.imagen" :src="item.imagen"/> 
                            </v-list-item-avatar>
                            <v-list-item-content>
                                {{item.desc_cliente}}
                                <v-list-item-subtitle><span><b>Código: </b><b style="color:orange;font-size:17px">{{item.cod_art}}</b></span></v-list-item-subtitle>
                                <v-row class="mt-3" style="margin-left: auto;">
                                <!-- <p class="mt-2" style="color:red">El precio del artículo fue modificado</p> -->
                                <v-list-item-subtitle v-if="!precioConIva && usuario.muestra_lista==1"><span style="font-size: 17px;color:orange;"><b>Precio actual del artículo: </b><b>{{Math.round(item.pr_final) | toCurrency}}</b></span></v-list-item-subtitle>
                                <v-list-item-subtitle v-if="precioConIva && usuario.muestra_lista==1"><span style="font-size: 17px;color:orange;"><b>Precio actual del artículo: </b><b style="color:orange;font-size:17px">{{Math.round(item.pr_vta) | toCurrency}}</b></span> <span style="color:orange;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                                <v-list-item-subtitle v-if="!precioConIva && item.cant_empaq!=1 && usuario.muestra_lista==1"><span style="font-size: 17px;color:orange;"><b>Precio por empaque: </b><b style="color:orange;font-size:17px">{{Math.round(item.pr_final*item.cant_empaq) | toCurrency}}</b></span></v-list-item-subtitle>
                                <v-list-item-subtitle v-if="precioConIva && item.cant_empaq!=1 && usuario.muestra_lista==1"><span style="font-size: 17px;color:orange;"><b>Precio por empaque: </b><b style="color:orange;font-size:17px">{{Math.round(item.pr_vta*item.cant_empaq) | toCurrency}}</b></span><span style="color:orange;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                                </v-row>
                            </v-list-item-content>
                            </v-list-item>
                            <v-divider :key="index" :inset="item.inset"></v-divider>
                        </template>
                    </v-list>
                </div>
                <!--FOOTER---------------->
                <v-footer color="#ef6b01" padless fixed outlined class="mt-5" style="height: 57px;">
                    <v-row  no-gutters class="text-center">
                        <v-col>
                            <v-btn small style="height: 54px;font-size: initial;" color="white" text elevation="0" @click="irCarrito">VOLVER AL CARRITO</v-btn>
                        </v-col>
                        <!-- <v-col>
                            <v-btn small style="height: 54px;font-size: initial;" color="white" text elevation="0" @click="dialogError = false">ACEPTAR</v-btn>
                        </v-col> -->
                    </v-row>
                </v-footer>
            </v-card>
        </v-dialog>
    </v-card>
  </div>
</template>

<script>
import ApiServer from '../api';
import Imagen from '../components/Imagen.vue'
import {mapState,mapActions} from 'vuex'
import moment from 'moment'
export default {
    name:'Pago',
    components:{Imagen},
    data(){
        return{
            cliente: null,
            cargando:false,
            snackbar:false,
            snackbarMsge:'',
            snackbarColor:'error',
            snackbarIcon:'mdi-close-circle',
            sinStock:[],
            nuevoPrecio:[],
            dialogError:false,
            observaciones:'',
            textoOrdenDeCompras:'',
            hacerSeguimiento:false
        }
    },
    methods:{
        ...mapActions(['borrarTodoElCarrito','editarCantCarrito','borrarArtCarrito','editarPrecioCarrito','editarDisponibleCarrito']),
        atras(){
            this.$router.go(-1)
        },
        irVentas(){
            this.$router.push({name: 'Home'});  
        },
        irCarrito(){
            this.$router.push({name: 'Carrito'});
        },
        detallecompra(){
            this.$router.push({name: 'DetalleCompra'});  
        },
        cerrarDialogObservaciones(){
            this.$router.back();
        },
        presupuestoVencido(fec){
            if(fec){
                let hoy=moment().format('DD-MM-YYYY')
                let fecVenc = moment(fec).format('DD-MM-YYYY')
                let dif = moment(hoy,"DD-MM-YYYY").diff(moment(fecVenc,"DD-MM-YYYY"),'days')
                if(dif<=0){
                    return false
                }else{
                    return true
                }
            }else{
                return true
            }       
        },
        async confirmarStockPrecio(){
            this.hacerSeguimiento = false
            try {
                for (const item of this.carrito){
                    //VERIFICO SI EL ART ESTA DE OFERTA
                    // let oferta = await ApiServer.getOferta(item.cod_art)
                    
                    
                    let result = await ApiServer.getCheckPrecioStock(item.cod_art,item.lpr_id,this.usuario.cli_id)

                    if(item.cantidad>result[0].DISPONIBLE){//MARCO EL CPR PARA HACER SEGUIMIENTO DE STOCK
                        this.hacerSeguimiento = true
                    }
                    

                    if(!this.presupuestoVencido(item.fec_cancel)){//SI VIENE DE UNA COTIZACION, Y NO ESTA VENCIDA, LE MANTENGO EL PRECIO
                        //BUSCO EL PRECIO EN EL CPRDET
                        let cprdet = await ApiServer.getCprDet(item.cpr,item.cod_art)
                        if(cprdet.length>0){
                            if(cprdet[0].PR_VTA!=result[0].PR_VTA || cprdet[0].PR_FINAL!=result[0].PR_FINAL){//SI EL PRECIO YA CAMBIO, RECALCULO EL DESCUENTO (O RECARGO)
                                let precioConDesc = cprdet[0].PR_VTA + cprdet[0].PR_VTA*(cprdet[0].PORC_DR/100)
                                result[0].DESCUENTO = (100-(precioConDesc * 100 /result[0].PR_VTA))*(-1)
                                item.descuento = (100-(precioConDesc * 100 /result[0].PR_VTA))*(-1)
                                item.descuento = item.descuento.toFixed(5)
                                item.pr_final = result[0].PR_FINAL
                                item.pr_vta = result[0].PR_VTA
                            }
                        }
                    }
                    // else if(result[0].DESCUENTO && result[0].DESCUENTO>0){//SI NO, ME FIJO SI TIENE DESCUENTO
                    //     result[0].PR_FINAL = result[0].PR_FINAL - (result[0].PR_FINAL * (result[0].DESCUENTO/100))
                    //     result[0].PR_VTA = result[0].PR_VTA - (result[0].PR_VTA * (result[0].DESCUENTO/100))
                    // }
                    
                    if(item.cantidad>result[0].DISPONIBLE && this.presupuestoVencido(item.fec_cancel)){//SI EL PRESUPUESTO NO ESTA VENCIDO,DEJO QUE AGREGE CANT MAYOR AL DISPONIBLE
                        let datos = {
                            art_id:item.art_id,
                            cod_art:item.cod_art,
                            desc_cliente: item.desc_cliente,
                            cantidad_anterior: item.cantidad,
                            disponible: result[0].DISPONIBLE,
                            imagen: item.imagen,
                            cant_empaq: item.cant_empaq,
                            esc:item.esc,
                            esc_plu: item.esc_plu,
                            escala:item.escala,
                            pr_vta:result[0].PR_VTA,
                            pr_final:result[0].PR_FINAL,
                            cant_empaq:result[0].CANT_EMPAQ
                        }
                        this.sinStock.push(datos)
                        // MODIFICO LA CANTIDAD EN EL CARRITO
                        if(result[0].DISPONIBLE>0){
                            let index = this.carrito.findIndex(i => i==item)
                            await ApiServer.putCarritoCliente({cantidad:result[0].DISPONIBLE,id:this.carrito[index].id})
                            this.editarCantCarrito({index:index,cantidad:result[0].DISPONIBLE})
                            this.editarDisponibleCarrito({index:index,disponible:result[0].DISPONIBLE})
                        } else {
                            //LO BORRO DEL CARRITO
                            let index = this.carrito.findIndex(i => i==item)
                            await ApiServer.deleteCarritoCliente(this.carrito[index].id)
                            this.borrarArtCarrito(this.carrito[index].id)
                        }
                    }
                    if((item.pr_final!=result[0].PR_FINAL || item.pr_vta!=result[0].PR_VTA) && this.presupuestoVencido(item.fec_cancel)){
                        let datos = {
                            art_id:item.art_id,
                            cod_art:item.cod_art,
                            desc_cliente: item.desc_cliente,
                            cantidad_anterior: item.cantidad,
                            disponible: result[0].DISPONIBLE,
                            pr_vta_anterior:item.pr_vta,
                            pr_final_anterior: item.pr_final,
                            pr_vta:result[0].PR_VTA,
                            pr_final:result[0].PR_FINAL,
                            imagen: item.imagen,
                            cant_empaq: item.cant_empaq,
                            esc:item.esc,
                            esc_plu: item.esc_plu,
                            escala:item.escala
                        }
                        this.nuevoPrecio.push(datos)
                        let index = this.carrito.findIndex(i => i==item)
                        this.editarPrecioCarrito({index:index,pr_vta:result[0].PR_VTA,pr_final:result[0].PR_FINAL})
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        async confirmar(){
            try {
                
                this.cargando = true
                await this.confirmarStockPrecio()
                if(this.nuevoPrecio.length>0){
                    await ApiServer.postUsoLog({
                       cod_cli: this.usuario.cod_cli,
                       usuario: this.usuario.nombreUsuario,
                       accion: 'CONFIRMAR_ORDEN_ERROR_PRECIO',
                       dato: ''
                   })
                }
                if(this.sinStock.length>0){
                    await ApiServer.postUsoLog({
                       cod_cli: this.usuario.cod_cli,
                       usuario: this.usuario.nombreUsuario,
                       accion: 'CONFIRMAR_ORDEN_ERROR_STOCK',
                       dato: ''
                   })
                }
                if(this.nuevoPrecio.length==0 && this.sinStock.length==0){
                    //REGISTO EL CONTENIDO DEL CARRITO
                    let arts = []
                    for(const item of this.carrito){
                        arts.push({
                            cod_art:item.cod_art,
                            cantidad:(item.cant_empaq==0)?(item.cantidad*1):(item.cantidad*item.cant_empaq)
                        })
                    }
                    await ApiServer.postCarritoLog({
                        cod_cli:this.usuario.cod_cli,
                        articulos:arts
                    })
                    //postUsoLog dentro de generarPedido
                    await this.generarPedido()
                }else{
                    this.cargando = false
                    this.dialogError = true
                }
            } catch (error) {
                console.log(error);
                this.cargando = false
            }
        },
        async generarPedido(){
            try {
                //TALONARIO
                let talonario = await ApiServer.getTalonarios(281);
                await ApiServer.putTalonarios(281)//TALONARIOS.NRO_CPR + 1
                let fecha = moment().format('DD-MM-YYYY')
                fecha = fecha.replace('-','.')
                fecha = fecha.replace('-','.')
                try {
                    let cpr_id = await ApiServer.getCprIdProcedure()
                    let respCpr = await ApiServer.postCpr({
                        id_pp:cpr_id.CPR_ID,
                        cpretc:12,
                        cprclasif:0,
                        cpr_tipo:"PE",
                        pv:talonario[0].NRO,
                        nro:talonario[0].NRO_CPR,
                        cpr_letra:"X",
                        tal_id:281,
                        fecha: fecha,
                        cli_id:this.cliente.CLI_ID,
                        rz:this.cliente.RZ,
                        cuit:this.cliente.CUIT,
                        iva_id:this.cliente.IVA_ID.trim(),
                        actividad:this.cliente.IB_ACTIV_ID,
                        dom:this.cliente.DOM,
                        loc_id:this.cliente.LOC_ID,
                        loc:this.cliente.LOCALIDAD,
                        cp:this.cliente.CP,
                        prov_id:this.cliente.PROVINCIA_ID.trim(),
                        tipo_pago_id:"CO",
                        lista_id:0,
                        lista:'LISTA BASE',
                        nota_cpr:(this.observaciones.trim()!='')?this.observaciones.trim():null,
                        cond_id:'1',
                        computa_iva:(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI')?'S':'N',
                        discrimina_imp:(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI')?'S':'N'
                    })
                    if(!respCpr.gdscode){

                        try {
                            let MTOTAL = 0
                            let MTOTALN = 0
                            let IVA21 = 0
                            let IVA105 = 0
                            for (const element of this.carrito){
                                let cprdet_id =await ApiServer.postCprDet({ //ME RETORNA EL CPRDET_ID
                                    id_pp:cpr_id.CPR_ID,
                                    art_id:element.art_id,
                                    cod_art:element.cod_art,
                                    descripcion:element.descripcion,
                                    desc_cliente:element.desc_cliente,
                                    mod:element.mod,
                                    med:element.med,
                                    descif:element.desc_if,
                                    cant:(element.cant_empaq==0)?(element.cantidad*1):(element.cantidad*element.cant_empaq),
                                    escala_id:element.escala_id,
                                    escala:element.escala,
                                    pr_cto_cpra:element.pr_cto_cpra,
                                    pr_cto:element.pr_cto,
                                    prfinal:element.pr_final,
                                    pr_vta:element.pr_vta,
                                    dep_id_org:33,
                                    dep_id_dest:33,
                                    porc_dr:element.descuento,
                                    monto_dr:0
                                }) 
                                
                                //INSERTO CPRDET_CPRDET
                                let cprDetCprDet = await ApiServer.postCprDetCprDet({
                                    cprdet_id: cprdet_id.CPRDET_ID,
                                    cantidad: (element.cant_empaq==0)?(element.cantidad*1):(element.cantidad*element.cant_empaq),
                                })
                                //CHECKEO QUE EXISTA STK_ID
                                let stkId = null
                                let resultStkID = await ApiServer.getStkID(element.art_id)
                                if(resultStkID.length==0){
                                    let resp = await ApiServer.postStock({
                                        art_id:element.art_id
                                    })
                                    stkId = resp.STK_ID
                                }else{
                                    stkId = resultStkID[0].STK_ID
                                }
                                //INSERTO EL MOVIMIENTO DE STOCK
                                let fechaHora = moment().format('DD-MM-YYYY HH:mm')
                                fechaHora = fechaHora.replace('-','.')
                                fechaHora = fechaHora.replace('-','.')
                                await ApiServer.postStockMov({
                                    cpr_id: cpr_id.CPR_ID,
                                    dep_id:1,
                                    art_id:element.art_id,
                                    fecha_hora:fechaHora,
                                    escala_id:element.escala_id,
                                    cant:(element.cant_empaq==0)?(element.cantidad*1):(element.cantidad*element.cant_empaq),
                                    cprdet_id: cprdet_id.CPRDET_ID,
                                    stock_id:stkId,
                                    cprdet_cprdet_id: cprDetCprDet.ITEM_ID
                                })
                                //INSERTO CPRDETIMP
                                let PRNETO = element.pr_vta + element.pr_vta*(element.descuento/100)
                                let PRFINAL = element.pr_final + element.pr_final*(element.descuento/100)
                                let IMPU=0
                                let IMP_ID=0
                                if(element.cant_empaq>1){
                                    PRNETO = (element.pr_vta +  element.pr_vta*(element.descuento/100))*element.cant_empaq
                                    PRFINAL =(element.pr_final + element.pr_final*(element.descuento/100))*element.cant_empaq
                                }
                                if(((PRFINAL/PRNETO)-1.21)<=0.01){
                                    IVA21=IVA21+(PRNETO*.21*(element.cantidad));
                                    IMPU=21;
                                    IMP_ID=1;
                                }
                                if(((PRFINAL/PRNETO)-1.105)<=0.01){
                                    IVA105=IVA105+(PRNETO*.105*(element.cantidad));
                                    IMPU=10.5;
                                    IMP_ID=2;
                                }
                                await ApiServer.postCprdetImp({
                                    cprdet_id:cprdet_id.CPRDET_ID,
                                    imp_id:IMP_ID,
                                    impu:IMPU,
                                    neto_mas_iva:PRNETO*element.cantidad,
                                    monto_iva:PRNETO*(IMPU/100)*(element.cantidad)
                                })
                                //SUMO LOS TOTALES
                                MTOTAL=MTOTAL+(PRFINAL)*(element.cantidad);
                                MTOTALN=MTOTALN+(PRNETO)*(element.cantidad);
                            }
                            //INSERTO EL PIE DEL COMPROBANTE
                            try {
                                await ApiServer.postCprpie({
                                    cpr_id:cpr_id.CPR_ID,
                                    impuesto_id:'-1',
                                    porc:'0',
                                    porcentaje:'0',
                                    total_neto:MTOTALN,
                                    total:MTOTAL
                                });
                                await ApiServer.postCprpie({
                                    cpr_id:cpr_id.CPR_ID,
                                    impuesto_id:'0',
                                    porc:'0',
                                    porcentaje:'0',
                                    total_neto:MTOTALN,
                                    total:MTOTAL
                                });
                                if(IVA21>=0.01){
                                    await ApiServer.postCprpie({
                                    cpr_id:cpr_id.CPR_ID,
                                    impuesto_id:'1',
                                    impuesto:'IVA 21%',
                                    clasif_id:'1',
                                    porc:'21',
                                    porcentaje:'0',
                                    total_neto:IVA21,
                                    total:IVA21/1.21,
                                    });
                                }
                                if(IVA105>=0.01){
                                    await ApiServer.postCprpie({
                                    cpr_id:cpr_id.CPR_ID,
                                    impuesto_id:'2',
                                    impuesto:'IVA 10.5%',
                                    clasif_id:'1',
                                    porc:'10.5',
                                    porcentaje:'0',
                                    total_neto:IVA105,
                                    total:IVA105/1.105,
                                    }); 
                                }
                                try {
                                    await ApiServer.putCprMonto(cpr_id.CPR_ID,{monto:MTOTAL})
                                    try {
                                        if(this.hacerSeguimiento){//HAY QUE HACER SEGUIMIENTO DE STOCK
                                            await ApiServer.putCprEstado(cpr_id.CPR_ID,{estado:'8'})
                                        }else{
                                            //CAMBIAR EL ESTADO A CPR_EST_ID=1
                                            await ApiServer.putCprEstado(cpr_id.CPR_ID,{estado:'1'})
                                        }
                                    } catch (error) {
                                        this.cargando=false
                                        console.log(error)
                                        this.snackbar=true
                                        this.snackbarMsge="Se produjo un error al cambiar estado"
                                        this.snackbarColor="error"
                                        this.snackbarIcon='mdi-close-circle'
                                    }
                                } catch (error) {
                                    this.cargando=false
                                    console.log(error)
                                    this.snackbar=true
                                    this.snackbarMsge="Se produjo un error al actualizar monto"
                                    this.snackbarColor="error"
                                    this.snackbarIcon='mdi-close-circle' 
                                }
                            } catch (error) {
                                this.cargando=false
                                console.log(error)
                                this.snackbar=true
                                this.snackbarMsge="Se produjo un error al generar cprpie"
                                this.snackbarColor="error"
                                this.snackbarIcon='mdi-close-circle'
                            }
                        } catch (error) {
                            this.cargando=false
                            console.log(error)
                            this.snackbar=true
                            this.snackbarMsge="Se produjo un error al generar cprdet"
                            this.snackbarColor="error"
                            this.snackbarIcon='mdi-close-circle'
                        }
                        this.cargando=false
                        this.snackbar=true
                        this.snackbarMsge="Pedido procesado"
                        this.snackbarColor="success"
                        this.snackbarIcon='mdi-check-circle'
                        await ApiServer.postUsoLog({
                            cod_cli: this.usuario.cod_cli,
                            usuario: this.usuario.nombreUsuario,
                            accion: 'BOTON CONFIRMAR',
                            dato: `Artículos: ${this.carrito.length}`
                        })
                        //INSERTO UN REGISTRO EN LOGISTICA_CPR_DATOS
                        await ApiServer.postLogisticaCprDatos({
                            id:cpr_id.CPR_ID,
                            cpr_id:cpr_id.CPR_ID,
                            texto:(this.textoOrdenDeCompras)?this.textoOrdenDeCompras:talonario[0].NRO_CPR
                        })

                        //SI SE CARGOARON ARTICULOS DE UN CPR, VINCULO LOS CPR GENERADOS
                        let cprsObj = this.carrito.filter(x=>x.cpr);
                        const cprsArray = cprsObj.map(x=>x.cpr)
                        const cprsArraySinRepetidos = [...new Set(cprsArray)];

                        for(const item of cprsArraySinRepetidos){
                            //CAMBIAR EL ESTADO A CPR_EST_ID=5
                            await ApiServer.putCprEstado(item,{estado:'10023'})
                            
                            //INSERTO UN REGISTRO EN CPR_CPR
                            await ApiServer.postCprCpr({
                                cpr_orig_id: item,
                                cpr_destino_id: cpr_id.CPR_ID
                            })
                            
                        }
                        //CAMBIO LA CANT_NO_PROC DEL CPR
                        for(const item of this.carrito){
                            if(item.cpr){
                                await ApiServer.putCprdetCantNoProc({
                                    cpr_id: item.cpr,
                                    cant: (item.cant_empaq==0)?(item.cantidad*1):(item.cantidad*item.cant_empaq),
                                    cod_art: item.cod_art
                                })
                            }
                        }

                        await ApiServer.deleteVaciarCarrito(this.usuario.cod_cli)
                        this.borrarTodoElCarrito()
                        this.$router.push({name:'PedidoIngresado',params:{nroPedido:talonario[0].NRO_CPR}})
                        
                    }else{
                        this.cargando=false
                        this.snackbar=true
                        this.snackbarMsge="Se produjo un error al procesar el pedido"
                        this.snackbarColor="error"
                        this.snackbarIcon='mdi-close-circle'
                    }
                } catch (error) {
                    this.cargando=false
                    console.log(error)
                    this.snackbar=true
                    this.snackbarMsge="Se produjo un error al generar cpr"
                    this.snackbarColor="error"
                    this.snackbarIcon='mdi-close-circle'
                }
            } catch (error) {
                this.cargando=false
                console.log(error);
                this.snackbar = true
                this.snackbarColor ='error'
                this.snackbarIcon = 'mdi-close-circle'
                this.snackbarMsge='Se produjo un error'
            }
        },
        porcentajeIva(item){
            //IVA 10.5 o 21
            let x = item.pr_final/item.pr_vta
            if(Math.abs(21-(x-1)*100)<Math.abs(10.5-(x-1)*100)){
                return 21
            }else{
                return 10.5
            }
        },
        descEmpaque(item){
            if(item.disponible==1){
                if(item.escala != item.esc){
                    return `${item.disponible} ${item.escala} / ${item.disponible*item.cant_empaq} ${item.esc}`
                }else{
                    return `${item.disponible} ${item.escala}`
                }
            }else{
                if(item.escala != item.esc){
                    return `${item.disponible} ${item.esc_plu} / ${item.disponible*item.cant_empaq} ${item.esc}`
                }else{
                    return `${item.disponible} ${item.esc_plu}`
                }
            }
        },
    },
    async mounted(){
        if(!this.datosCompra.tipoEnvio || !this.datosCompra.medioPago){
            this.$router.push({name: 'Envio'}); 
        }else{
            try {
                this.cargando = true
                let result = await ApiServer.getDatosCliente(this.datosCompra.codCli)
                if(result.length>0){
                    let index = result.findIndex(x=>x.DOM_CLASIF_ID.trim()=='DE')
                    if(index>=0){
                        this.cliente = result[index]
                    }else{
                        this.cliente = result[0]
                    }
                }
                this.cargando = false
                await ApiServer.postUsoLog({
                   cod_cli: this.usuario.cod_cli,
                   usuario: this.usuario.nombreUsuario,
                   accion: 'CONFIRMAR ORDEN',
                   dato: ''
               })
            } catch (error) {
                this.cargando = false
                console.log(error);
                this.snackbar = true
                this.snackbarColor ='error'
                this.snackbarIcon = 'mdi-close-circle'
                this.snackbarMsge='Error al consultar datos'
            }
        }
    },
    computed:{
        ...mapState(['usuario','datosCompra','carrito']),
        total(){
            let total=0
            if(this.carrito.length>0){
                this.carrito.forEach(item => {
                    let empaque = (item.cant_empaq!=0)? item.cant_empaq : 1 
                    total+=(item.pr_final + item.pr_final*(item.descuento/100)) * empaque * item.cantidad
                });
            }
            return total
        },
        totalSinIva(){
            let total=0
            if(this.carrito.length>0){
                this.carrito.forEach(item => {
                    let empaque = (item.cant_empaq!=0)? item.cant_empaq : 1 
                    total+=(item.pr_vta + item.pr_vta*(item.descuento/100)) * empaque * item.cantidad
                });
            }
            return total

            
        },
        totalContado(){
            if(this.datosCompra.tipoEnvio=='Envio' && this.datosCompra.costoFlete){
                return this.total + this.datosCompra.costoFlete
            }else{
                return this.total
            }
        },
        productos(){
            let cant=0
            if(this.carrito.length>0){
                this.carrito.forEach(item => {
                    cant+=parseInt(item.cantidad)
                });
               return cant
            }else if(isNaN(cant)){
                return 0
            }else{
                return cant
            }
        },
        precioConIva(){
            if(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI'){
                return true
            }else{
                return false
            }
        }
    },
}
</script>

<style>
 .letra{
        font-family: sans-serif !important;
    }
    .fixed-bar {
        position: sticky;
        position: -webkit-sticky; /* for Safari */
        top: 0em;
        z-index: 2;
    }
    .v-label{
        font-size: 20px;
        font-weight: 450;
    }
</style>