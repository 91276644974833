<template>
   <div>
    <v-card elevation="0" >
        <v-toolbar color="#ef6b01" rounded="0" elevation="5" class="fixed-bar">
            <v-btn icon dark @click="atras"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
            <v-card-title style="color:white" class="text-truncate text-center">Todas las categorías</v-card-title>
        </v-toolbar>
        <div class="ml-2 mr-2 mt-5 text-center">
            <v-text-field autofocus type="search" v-model="busqueda" color="orange" 
                label="Buscar Producto"
                outlined
                clearable
                clear-icon="fas fa-times"
                @keyup.enter="buscar"
            >

            <font-awesome-icon style="font-size:20px;color:orange" icon="magnifying-glass" slot="prepend-inner"/>
        </v-text-field>
        </div>

        <div class="text-center" v-if="articulos.length==0">
            <!-- <font-awesome-icon style="color: rgb(155 155 155 / 72%); font-size:30px" icon="magnifying-glass" /> -->
            <v-icon style="color: rgb(155 155 155 / 72%); font-size:30px">mdi-magnify</v-icon>
            <p class="mt-2" style="color: grey;font-size: 17px;">Buscar producto en<br> todas las categorías</p>
        </div>

        <div style="margin-inline: 15px;" class="mb-12 mt-3">
            <v-list three-line v-if="this.articulos.length>0">
                <template v-for="(item, index) in articulos" >
                    
                    <v-list-item  @change="(agregarCarrito==false)? clicArt(item): ''" :key="item.COD_ART" v-ripple="{ class: 'orange--text' }">
                    
                    <v-list-item-avatar :rounded="true" size="80px" horizontal>
                        <Imagen v-if="item.imagen" :src="item.imagen" :src2="item.etiqueta"/>
                        <!-- <v-img contain :src="`${item.imagen}`" max-width="160" max-height="160" :key="componentKey"  @error="onErrorImg(item)"/> -->
                    </v-list-item-avatar>

                    <v-list-item-content>
                        {{item.DESC_CLIENTE}}
                        <v-list-item-subtitle><span><b>Código: </b><b style="color:orange;font-size:17px">{{item.COD_ART}}</b></span></v-list-item-subtitle>
                        <v-list-item-subtitle><span><b>Empaque: </b><b style="color:orange;font-size:17px">{{(item.ESCALA!=item.ESC)?item.ESCALA:''}} {{item.CANT_EMPAQ}} {{item.ESC}}</b></span></v-list-item-subtitle>
                        <v-list-item-subtitle v-if="usuario.muestra_lista==1"><span><b>Precio contado: </b><b style="color:orange;font-size:17px">{{precioArt(item) | toCurrency}}</b></span><span v-if="precioConIva" style="color:orange;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                        <v-list-item-subtitle v-if="item.CANT_EMPAQ!=1 && usuario.muestra_lista==1"><span><b>Precio por empaque contado: </b><b style="color:orange;font-size:17px">{{precioPorEmpaque(item) | toCurrency}}</b></span><span v-if="precioConIva" style="color:orange;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                        <div style="justify-content: end; display: grid;">
                            <v-btn v-if="(carrito.findIndex(i => i.cod_art==item.COD_ART)==-1)" color="orange" @click="agregarCarrito=true,agregar(item)" elevation="0" outlined small dark style="max-width: 100px;"><font-awesome-icon style="font-size:20px" icon="cart-shopping"/>{{(usuario.muestra_lista==1)?'Agregar':'Cotizar'}}</v-btn>
                            <template v-if="(carrito.findIndex(i => i.cod_art==item.COD_ART)!=-1)">
                                <p style="color:green;margin: 0px;" class="mt-2"><v-icon style="font-size:20px" color="green">mdi-cart-check</v-icon>Agregado al carrito</p>
                                <v-row class="mt-3" style="margin: auto;">
                                    <v-btn color="orange" fab icon small @click="cantidadMenos(item)"><v-icon>mdi-minus-thick</v-icon></v-btn>
                                    <v-text-field
                                    v-model="carrito[carrito.findIndex(i => i.cod_art==item.COD_ART)].cantidad"
                                    label="Cantidad"
                                    type="number"
                                    dense
                                    flat
                                    solo
                                    color="orange"
                                    hide-details="true"
                                    style="max-width: 95px;align-items: center;"
                                    @click="verDialogCantidad(carrito[carrito.findIndex(i => i.cod_art==item.COD_ART)])"
                                    class="centered-input text-orange"
                                    readonly
                                    ></v-text-field>
                                    <v-btn color="orange" fab icon small @click="cantidadMas(item)"><v-icon>mdi-plus-thick</v-icon></v-btn>
                                </v-row>
                                <p class="mt-2" style="color:orange;text-align: center;max-width: 168px;">{{descEmpaque(item)}}</p>
                            </template>
                        </div>
                    </v-list-item-content>
                    
                    </v-list-item>
                    <v-divider :key="index" :inset="item.inset"></v-divider>
        
                </template>
            </v-list>
        </div>
        <!--FOOTER---------------->
        <v-footer color="#ef6b01" padless fixed class="mt-5" style="justify-content: end" rounded="0">
            <v-row justify="center" no-gutters style="height: 45px;">
                <v-col cols="4">
                </v-col>
                <v-col cols="4" class="text-center">
                    <v-btn style="bottom: 23px;" fab color="#ef6b01" elevation="20" @click="irHome" class="my-2"><font-awesome-icon style="font-size:25px;color: white" icon="plus"/></v-btn>
                </v-col>
                <v-col cols="4" class="text-end">
                    <v-btn @click="irCarrito" icon class="my-1 mr-2" color="white"><font-awesome-icon style="font-size:22px" icon="cart-shopping"/>{{(carrito.length>0)?carrito.length:''}}</v-btn>
                </v-col>
            </v-row>
        </v-footer>
    </v-card>
    <!-- DIALOG CANTIDAD -->
        <v-dialog v-model="dialogCantidad" max-width="300px" persistent>
            <v-card v-if="itemCantSelected">
                <v-toolbar color="#ef6b01">
                    <v-btn icon dark @click="cerrarDialogCambiarCantidad"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
                    <v-card-title style="color:#ffff">Cambiar cantidad</v-card-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="mt-3" v-if="itemCantSelected">
                    <v-text-field autofocus outlined style="font-size: 20px;" v-model="carrito[carrito.findIndex(i => i.cod_art==itemCantSelected.cod_art)].cantidad" label="Cantidad" type="number" solo color="orange"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-if="itemCantSelected" color="orange" elevation="5" outlined @click="cambiarCantidad()" :disabled="!validarCantidad">Aceptar</v-btn>
                    <v-btn color="grey" elevation="5" @click="cerrarDialogCambiarCantidad" outlined>Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    <!-- ----CARGANDO------- -->
        <v-overlay :value="cargando">
            <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            indeterminate
                            size="70"
                            width="7"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center">
                        <span>{{textoCargando}}</span>
                    </v-col>
                </v-row>
        </v-overlay>
        <!-- SNACKBAR -->
        <v-snackbar v-model="snackbar" :color="snackbarColor" text outlined class="mb-15">
            {{ snackbarMsge }}
            <template v-slot:action="{ attrs }">
                <v-btn icon :color="snackbarColor" text v-bind="attrs" @click="snackbar = false"><v-icon>{{snackbarIcon}}</v-icon></v-btn>
            </template>
        </v-snackbar>
        <!-- DIALOG ARTICULO -->
        <v-dialog v-model="dialogArticulo" fullscreen hide-overlay transition="dialog-bottom-transition" z-index="1">
            <Articulo v-if="artSelected" :codArt="artSelected" :key="componentKey"></Articulo>
        </v-dialog>
  </div>
</template>

<script>
import ApiServer from '../api';
import {mapState,mapActions} from 'vuex';
import Imagen from '../components/Imagen.vue'
import Articulo from '../components/Articulo.vue'
export default {
    name:'Buscador',
    components:{Imagen, Articulo},
    data(){
        return{
            busqueda:null,
            articulos:[],
            cargando:false,
            textoCargando:null,
            agregarCarrito: false,
            snackbar:false,
            snackbarColor:'error',
            snackbarMsge:'',
            snackbarIcon:'mdi-check-circle',
            itemCantSelected:null,
            dialogCantidad:false,
            dialogArticulo: false,
            artSelected: null,
            componentKey:0
        }
    },
    methods:{
        ...mapActions(['agregarAlCarrito','editarCantCarrito','borrarArtCarrito']),
        async buscar(){
            document.activeElement.blur();
            if(this.usuario && this.usuario.listas.length>0){
                try {
                    this.cargando = true
                    this.textoCargando = 'Buscando artículos...'
                    this.articulos = await ApiServer.getBuscarArt(this.busqueda,this.usuario.listas[0].LISTA_ID,this.usuario.cli_id)
                    
                    if(this.usuario.listas.length>1){
                        for (let i = 1; i < this.usuario.listas.length; i++) {
                            let siguenteLista = await ApiServer.getBuscarArt(this.busqueda,this.usuario.listas[i].LISTA_ID,this.usuario.cli_id)
                            if(siguenteLista.length>0){
                                siguenteLista.forEach(item => {
                                    if((this.articulos.findIndex(i => i.ART_ID==item.ART_ID))==-1){
                                        this.articulos.push(item)
                                    }
                                });
                            }
                        }
                    }
                    let etiquetas = []
                    if(this.articulos.length>0){
                        etiquetas = await ApiServer.getEtiquetasProductos()
                    }
                    this.articulos.forEach(element => {
                        element.imagen=`https://loguin.com.ar/img/${element.ART_ID}.jpg?t=${(new Date()).getTime()}`;
                        if(element.CANT_EMPAQ == 0){
                            element.CANT_EMPAQ = 1
                        }
                        //SI TIENE DESCUENTO
                        // if(element.DESCUENTO && element.DESCUENTO>0){
                        //     element.PR_FINAL = element.PR_FINAL - element.PR_FINAL * (element.DESCUENTO/100)
                        //     element.PR_VTA = element.PR_VTA - element.PR_VTA * (element.DESCUENTO/100)
                        // }
                        element.etiqueta = null
                        let indexEtiqueta = etiquetas.findIndex(x=>x.cod_art==element.COD_ART)
                        if(indexEtiqueta>-1){
                            element.etiqueta = `https://loguin.com.ar/img/imag_etiquetas/${etiquetas[indexEtiqueta].etiqueta}?t=${(new Date()).getTime()}` 
                        }
                    });
                    this.cargando = false
                    console.log(this.articulos);
                    
                    if(this.articulos.length==0){
                        this.snackbar = true
                        this.snackbarColor = 'error'
                        this.snackbarIcon = "mdi-close-circle"
                        this.snackbarMsge = "No se encontraron artículos"
                    }
                    await ApiServer.postUsoLog({
                        cod_cli: this.usuario.cod_cli,
                        usuario: this.usuario.nombreUsuario,
                        accion: 'BUSQUEDA',
                        dato: this.busqueda
                    })
                } catch (error) {
                    this.cargando = false
                    console.log(error);
                    this.snackbar = true
                    this.snackbarColor = 'error'
                    this.snackbarIcon = "mdi-close-circle"
                    this.snackbarMsge = "Error al buscar artículos"
                }
            }
        },
        atras(){
            this.$router.go(-1)
        },
        irCarrito(){
            this.$router.push({name: 'Carrito'});
        },
        irHome(){
            this.$router.push({name: 'Home'});
        },
        clicArt(item){
            // this.$router.push({name: 'Articulo', params: { codArt: item.COD_ART,datosArticulo:item }})
            this.artSelected = item.COD_ART
            this.$router.push('#articulo')
            this.componentKey++//PARA ACTUALIZAR
        },
        async agregar(item){
            this.cargando = true
            this.textoCargando = 'Agregando artículo'
            try {
                let result = await ApiServer.postCarritoCliente({
                    cod_cli:this.usuario.cod_cli,
                    cod_art:item.COD_ART,
                    cantidad: 1,
                    lpr_id:item.LISTA_ID,
                    usuario: this.usuario.nombreUsuario,
                    accion:'CARRITO'
                });
                 this.agregarAlCarrito({
                    id:result.insertId,
                    lpr_id:item.LISTA_ID,
                    cod_art:item.COD_ART,
                    art_id:item.ART_ID,
                    desc_cliente: item.DESC_CLIENTE,
                    descripcion: item.DESCRIPCION,
                    mod:item.MOD,
                    med:item.MED,
                    desc_if:item.DESC_IF,
                    cantidad:1,
                    escala_id:item.ESCALA_ID,
                    escala:item.ESCALA,
                    esc:item.ESC,
                    esc_plu:item.ESC_PLU,
                    pr_vta:item.PR_VTA,
                    pr_cto_cpra:item.PR_CTO_CPRA,
                    pr_cto:item.PR_CTO,
                    pr_final:item.PR_FINAL,
                    descuento:item.DESCUENTO,
                    disponible:item.DISPONIBLE,
                    cant_empaq:item.CANT_EMPAQ,
                    ord_rec_str:item.ORD_REC_STR,
                    ean:item.EAN,
                    imagen : `https://loguin.com.ar/img/${item.ART_ID}.jpg?t=${(new Date()).getTime()}`,
                    etiqueta : item.etiqueta
                })
                this.cargando = false
            } catch (error) {
                this.cargando = false
                console.log(error);
            }
            setTimeout(()=>{
                this.agregarCarrito=false
            },1000)
        },
        async cantidadMas(item){
            this.cargando = true
            this.textoCargando = 'Aumentando cantidad'
            this.agregarCarrito=true
            try {
                let index = this.carrito.findIndex(i => i.cod_art==item.COD_ART)
                if((this.carrito[index].cantidad*this.carrito[index].cant_empaq)<item.DISPONIBLE){
                    let cant = Number(this.carrito[index].cantidad)+1
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                    this.cargando = false
                    setTimeout(()=>{
                        this.agregarCarrito=false
                    },2000)
                }else{
                    this.cargando = false
                    this.snackbar = true
                    this.snackbarColor ='error'
                    this.snackbarIcon = 'mdi-close-circle'
                    this.snackbarMsge='Sin stock'
                }
                
            } catch (error) {
                console.log(error);
                this.cargando = false
            }
            
        },
        async cantidadMenos(item){
            this.cargando = true
            this.textoCargando = 'Disminuyendo cantidad'
            this.agregarCarrito=true
            try {
                let index = this.carrito.findIndex(i => i.cod_art==item.COD_ART)
                if(this.carrito[index].cantidad>1){
                    let cant = this.carrito[index].cantidad-1
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                }else{
                    await ApiServer.deleteCarritoCliente(this.carrito[index].id)
                    this.borrarArtCarrito(this.carrito[index].id)
                }
                this.cargando = false
                setTimeout(()=>{
                    this.agregarCarrito=false
                },2000)
            } catch (error) {
                 console.log(error);
                this.cargando = false
            }
            
        },
        verDialogCantidad(item){
            this.agregarCarrito=true
            this.itemCantSelected = item
            this.dialogCantidad = true
            setTimeout(()=>{
                this.agregarCarrito=false
            },2000)
        },
        async cambiarCantidad(){
            this.dialogCantidad = false
            this.cargando = true
            this.textoCargando = 'Cambiando cantidad...'
            let cant = Number(this.itemCantSelected.cantidad)
            try {
                if(cant>0 && (cant <= this.itemCantSelected.disponible)){
                    let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                }
                this.cargando = false
                this.itemCantSelected = null
            } catch (error) {
                this.cargando = false
                this.dialogCantidad = false
                this.itemCantSelected = null
                console.log(error);
            }
        },
        descEmpaque(item){
            let cant = this.carrito[this.carrito.findIndex(i => i.cod_art==item.COD_ART)].cantidad
            if(cant==1){
                if(item.ESCALA != item.ESC){
                    return `${cant} ${item.ESCALA} / ${cant*item.CANT_EMPAQ} ${item.ESC}`
                }else{
                    return `${cant} ${item.ESCALA}`
                }
            }else{
                if(item.ESCALA != item.ESC){
                    return `${cant} ${item.ESC_PLU} / ${cant*item.CANT_EMPAQ} ${item.ESC}`
                }else{
                    return `${cant} ${item.ESC_PLU}`
                }
            }
        },
        cerrarDialogCambiarCantidad(){
            let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
            let cant = this.carrito[index].cantidad*this.carrito[index].cant_empaq
            if(cant>=this.itemCantSelected.disponible){
                this.carrito[index].cantidad=this.itemCantSelected.disponible/this.itemCantSelected.cant_empaq   
            }
            if(cant<=0){
                this.carrito[index].cantidad = 1
            }
            this.dialogCantidad = false
            this.itemCantSelected = null
        },
        porcentajeIva(item){
            //IVA 10.5 o 21
            let x = item.PR_FINAL/item.PR_VTA
            if(Math.abs(21-(x-1)*100)<Math.abs(10.5-(x-1)*100)){
                return 21
            }else{
                return 10.5
            }
        },
        precioArt(item){
            let precio = null
            if(this.precioConIva){
                precio = item.PR_VTA + item.PR_VTA*(item.DESCUENTO/100)
            }else{
                precio = item.PR_FINAL + item.PR_FINAL*(item.DESCUENTO/100)
            }
            return Math.round(precio)
        },
        precioPorEmpaque(item){
            let precio = null
            if(this.precioConIva){
                let pr_vta= item.PR_VTA + item.PR_VTA*(item.DESCUENTO/100)
                precio = Math.round(pr_vta)*item.CANT_EMPAQ
            }else{
                let pr_final = item.PR_FINAL + item.PR_FINAL*(item.DESCUENTO/100)
                precio = Math.round(pr_final)*item.CANT_EMPAQ
            }
            return precio
        }

    },
    mounted(){
        if(this.$route.hash=='#articulo'){
            this.$router.replace({ path: '/buscador' })
        }
    },
    computed:{
        ...mapState(['usuario','carrito']),
        validarCantidad(){
            let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
            let cant = this.carrito[index].cantidad*this.carrito[index].cant_empaq
            if(cant>this.itemCantSelected.disponible || cant<=0){
                return false
            }else{
                return true
            }
        },
        precioConIva(){
            if(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI'){
                return true
            }else{
                return false
            }
        }
    },
    watch:{
        '$route.hash'(newHash,oldHash) {
            if(newHash === '#articulo') {
                this.dialogArticulo = true
            } else if (oldHash === '#articulo'){
                this.dialogArticulo = false
            }
        }
    }
}
</script>

<style scoped>
    .centered-input >>> input {
      text-align: center
    }
    .cant.v-text-field--outlined >>> fieldset {
    border-color: orange;
    border-width: 2px;
    height: 35px;
    align-self: start;
    margin-top: 6px
    }
    .fixed-bar {
        position: sticky;
        position: -webkit-sticky; /* for Safari */
        top: 0em;
        z-index: 2;
    }
</style>