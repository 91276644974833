<template>
    <div>
        <v-card elevation="0">
            <v-toolbar color="#ef6b01" rounded="0" elevation="5" class="fixed-bar">
                <v-btn icon dark @click="atras"><font-awesome-icon style="font-size:23px" icon="arrow-left"/></v-btn>
                <v-card-title style="color:white" class="text-truncate text-center">Mi perfil</v-card-title>
            </v-toolbar>
            <v-card-text class="text-center mb-15">
                <v-row justify="center" no-gutters >
                    <v-col style="text-align: initial;margin-left: 40px;">
                       <p style="font-size:20px;font-weight: 800;">{{(usuario.nombre)?usuario.nombre:''}} {{(usuario.apellido)?usuario.apellido:''}}</p> 
                       <p style="font-size:18px;font-weight: 600;">{{(usuario.email)?`Correo: ${usuario.email}`:''}}</p>
                        <p style="font-size:18px;font-weight: 600;">{{(usuario.celular)?`Teléfono: ${usuario.celular}`:''}}</p>
                    </v-col>
                </v-row>
                <v-row justify="center" no-gutters class="mt-5">
                    <v-col style="text-align: initial;margin-left: 15px;">
                        <p style="font-size:18px;font-weight: 600;color:orange">Mi dirección</p>
                        <p v-if="cliente" style="font-size:17px">{{cliente.DOM}}</p>
                        <p v-if="cliente" style="font-size:17px">{{`${cliente.LOCALIDAD} , ${cliente.PROVINCIA}`}} , Argentina</p>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row justify="center" no-gutters class="mt-5">
                    <v-col style="text-align: initial;margin-left: 15px;">
                        <p style="font-size:18px;font-weight: 600;color:orange">Mi transporte</p>
                        <v-list v-if="this.transportes.length>0" >
                            <template v-for="(item, index) in transportes">
                                <v-list-item :key="index" v-ripple="{ class: 'orange--text' }" >
                                <!-- <v-list-item-avatar :rounded="true" size="80px" horizontal>
                                    <v-img contain src="../assets/perfil.jpg" max-width="160" max-height="160"/>
                                </v-list-item-avatar> -->
                                <v-list-item-content>
                                    <p style="font-size:17px">{{item.NOM_FANTASIA}}</p>
                                </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row v-if="usuario.muestraCatalogoPuntos==1" justify="center" no-gutters class="mt-5">
                    <v-col style="text-align: initial;margin-left: 15px;">
                        <p style="font-size:18px;font-weight: 600;color:orange">Mis puntos</p>
                        <v-row>
                            <v-col cols="4">
                                <p style="font-size:17px;line-height: 38px;">{{puntos}} puntos</p>
                            </v-col>
                            <v-col style="text-align: initial;margin-left: 15px;">
                                <v-btn @click="irCatalogo" outlined color="orange">VER CATÁLOGO</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <p class="mt-3 ml-3"  style="text-align: initial;font-size:18px;">Realizá compras por la Aplicación de Ferrenet para ir sumando puntos</p>
                <v-divider class="mt-5" v-if="usuario.muestraCatalogoPuntos==1"></v-divider>
            </v-card-text>
            <!--FOOTER---------------->
            <v-footer color="#ef6b01" padless fixed class="mt-5" style="justify-content: end" rounded="0">
                <v-row justify="center" no-gutters style="height: 45px;">
                    <v-col cols="4"></v-col>
                    <v-col cols="4" class="text-center">
                        <v-btn style="bottom: 23px;" fab color="#ef6b01" elevation="20" @click="irHome" class="my-2"><font-awesome-icon style="font-size:25px;color: white" icon="plus"/></v-btn>
                    </v-col>
                    <v-col cols="4" class="text-end">
                        <v-btn @click="irCarrito" icon class="my-1 mr-2" color="white"><font-awesome-icon style="font-size:22px;color: white;" icon="cart-shopping"/>{{(carrito.length>0)?carrito.length:''}}</v-btn>
                    </v-col>
                </v-row>
            </v-footer>
        </v-card>
        <!-- ----CARGANDO------- -->
        <v-overlay :value="cargando">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import ApiServer from '../api';
import {mapState,mapActions} from 'vuex'
export default {
    name:'MiPerfil',
    data(){
        return{
            cliente: null,
            transportes:[],
            cargando:false,
            puntos:0
        }
    },
    methods:{
        atras(){
            this.$router.go(-1)
        },
        irHome(){
            this.$router.push({name: 'Home'});  
        },
        irCarrito(){
            this.$router.push({name: 'Carrito'});
        },
        irCatalogo(){
            this.$router.push({name: 'Catalogo'});
        }
    },
    async mounted(){
        try {
            this.cargando = true
                let result = await ApiServer.getDatosCliente(this.usuario.cod_cli)
                if(result.length>0){
                    let index = result.findIndex(x=>x.DOM_CLASIF_ID.trim()=='DE')
                    if(index>=0){
                        this.cliente = result[index]
                    }else{
                        this.cliente = result[0]
                    }
                }
                let respTransporte = await ApiServer.getTransportesCliente(this.usuario.cod_cli)
                this.transportes = respTransporte
                
                let respPuntos = await ApiServer.getPuntosCliente(this.usuario.cod_cli)
                console.log(respPuntos);
                if(respPuntos.length>0)
                this.puntos = respPuntos[0].puntos
                this.cargando = false
        } catch (error) {
            this.cargando = false
            console.log(error);
        }
    },
    computed:{
        ...mapState(['usuario','carrito']),
    }
}
</script>

<style>

</style>