<template>
    <div>
        <!-- IMAGEN INICIO -->
        <v-dialog  v-model="dialogPantallaInicio" persistent  transition="dialog-bottom-transition" style="height: 100vh;">
            <v-img @load="imagenInicioLista=true,imagenInicioCargada()" contain :src="`${imagenInicial}`" style="max-height: 85vh;  max-width: 100vw;"/>
            <v-btn v-if="imagenInicioLista"  color="#ff000e" dark @click="dialogPantallaInicio = false">Cerrar</v-btn>
        </v-dialog>

        <!-- BOTON WHATSAPP -->
        <!-- <a href="https://wa.me/5493435075085" target="_blank" class="btn-wsp"> 
            <v-icon style="color: white;font-size: 50px; margin-bottom: 10px;">mdi-whatsapp</v-icon>
        </a> -->

        <!--LISTA DE RUBROS PADRES----->
        <v-card elevation="0" :style="(botonesInferiores.length>0)?'margin-bottom: 130px;':''">
            <template v-if="paltallaTelefono">
                <v-list v-if="this.ArtPadres.length>0" style="margin-top: -20px;">
                    <template v-for="(item) in ArtPadres" >
                        
                        <v-list-item @change="clicFila(item)" :key="item.RUBRO"  v-ripple="{ class: 'orange--text' }" style="padding:0px">
                            <v-list-item-content style="padding:0px">
                               <!-- <v-img :src="require(`../assets/rubros/${item.RUBRO_ID}.png`)"></v-img> -->
                               <v-img contain :src="`${item.imagen}`" max-height="150px" :key="componentKey"  @error="onErrorImg(item)"/>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- <v-divider :key="item.RUBRO_ID" dark class="orange"></v-divider> -->
                        
                    </template>
                </v-list>
            </template>
            <template v-else>
                <v-row style="justify-content: center; margin-inline: auto;" class="mt-5 mb-10">
                    <v-col v-for="item in ArtPadres"  :key="item.RUBRO"  cols="12" sm="6" md="4" lg="3" style="padding-bottom: 0px">
                        <v-card elevation="0" @click="clicFila(item)">
                            <v-img contain :src="`${item.imagen}`" max-height="150px" :key="componentKey"  @error="onErrorImg(item)"/>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <!--FOOTER---------------->
            <v-footer padless fixed class="mt-5" rounded="0">
                <v-card class="flex" dense flat tile>
                    <v-card-title class="white text-center justify-center" style="padding: 0px;" v-if="botonesInferiores.length>0">
                        <v-row style="height: 110px">
                            <v-col  v-if="cantOfertas>0" style="justify-content: center;display: flex;">
                                <v-badge left offset-x="21" offset-y="24" overlap  :content="cantOfertas" color="red">
                                    <v-avatar style="width:58px;height:97%;cursor: pointer;" rounded="0" @click="listaArticulos('ofertas')">
                                        <v-img src="../assets/Ofertas.png" style="background-size: contain;"></v-img>
                                    </v-avatar>
                                </v-badge>
                            </v-col>
                            <v-col v-if="cantFavoritos>0" style="justify-content: center;display: flex;">
                                <v-badge left offset-x="38" offset-y="24"  overlap :content="cantFavoritos" color="red" >
                                    <v-avatar style="width:64px;height:96%;cursor: pointer;" rounded="0" @click="listaArticulos('favoritos')">
                                        <v-img src="../assets/Favoritos.png" style="background-size: contain;"></v-img>
                                    </v-avatar>
                                </v-badge>
                            </v-col>
                            <v-col  v-if="cantSugeridos>0" style="justify-content: center;display: flex;">
                                <v-badge left offset-x="31" offset-y="24" overlap :content="cantSugeridos" color="red" >
                                    <v-avatar style="width:70px;height:98%;cursor: pointer;" rounded="0" @click="listaArticulos('sugeridos')">
                                        <v-img src="../assets/Sugeridos.png" style="background-size: contain;" ></v-img>
                                    </v-avatar>
                                </v-badge>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-card-text class="py-2 text-end justify-end" style="background-color:#ef6b01;height: 44px;">
                        <v-btn @click="irCarrito" icon  color="white" style="height: 10px;"><font-awesome-icon style="font-size:22px;color:#ffff" icon="cart-shopping"/>{{(carrito.length>0)?carrito.length:''}}</v-btn>
                    </v-card-text>
                    </v-card>
                
            </v-footer>
        </v-card>
        <!-- ----CARGANDO------- -->
        <v-overlay :value="cargando">
            <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            indeterminate
                            size="70"
                            width="7"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center">
                        <span>{{msjeCargando}}</span>
                    </v-col>
                </v-row>
        </v-overlay>
    </div>
</template>

<script>
import ApiServer from '../api';
import Imagen from '../components/Imagen.vue'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'Home',
  comments:{Imagen},
  data(){
    return{
        ArtPadres:[],
        ArtHijos:[],
        ArtsPorRubro:[],
        cargandoArticulos:false,
        snackbarArt:false,
        snackbarArtMsge:'',
        snackbarArtColor:'error',
        snackbarArtIcon:'mdi-close-circle',
        cliente:null,
        cargando:false,
        msjeCargando:'',
        botonesInferiores:[],
        cantFavoritos:0,
        cantOfertas:0,
        cantSugeridos:0,
        componentKey:0,
        imagenInicial:null,
        dialogPantallaInicio:false,
        imagenInicioLista:false,
        datosPantallInicio:[]

    }
  },
   methods:{
    ...mapActions(['pantallaInicialVista']),
        async clicFila(fila){
            this.cargando=true
            try {
                let resp = await ApiServer.getArtRubrosHijos(fila.RUBRO_ID,this.usuario.listas[0].LISTA_ID)
                await ApiServer.postUsoLog({
                    cod_cli: this.usuario.cod_cli,
                    usuario: this.usuario.nombreUsuario,
                    accion: 'FAMILIA',
                    dato: fila.RUBRO
                })
                this.$router.push({name: 'RubrosHijos', params: { rubroId: fila.RUBRO_ID, rubro: fila.RUBRO }});  
                this.cargando=true
            } catch (error) {
                this.cargando=true
                console.log(error)
            }
        },
        irCarrito(){
            this.$router.push({name: 'Carrito'});
        },
        async getImagenRubro(rubro_id){
            try {
                return await ApiServer.getImagenRubro(rubro_id)
            } catch (error) {
                console.log(error);
            }
        },
        onErrorImg(item){
            item.imagen = require('../assets/default_image.png')
            this.componentKey++//PARA ACTUALIZAR
            
        },
        async listaArticulos(agrupador){
            try {
                await ApiServer.postUsoLog({
                   cod_cli: this.usuario.cod_cli,
                   usuario: this.usuario.nombreUsuario,
                   accion: agrupador.toUpperCase(),
                   dato: ''
               })
               this.$router.push({name: 'ListaDeArticulos',params: { agrupador: agrupador }});
            } catch (error) {
                console.log(error);
            }
        },
        async imagenInicioCargada(){
            // try {
            //     if(this.datosPantallInicio.visto==0){
            //         this.pantallaInicialVista()
            //         setTimeout(()=>{
            //             this.dialogPantallaInicio=false
            //         },this.datosPantallInicio.tiempo_1*1000)
            //         await ApiServer.putPantallaInicial(this.usuario.cod_cli)//LO PONGO EN VISTO = 1
            //     }else{
            //         this.pantallaInicialVista()
            //         setTimeout(()=>{
            //             this.dialogPantallaInicio=false
            //         },this.datosPantallInicio.tiempo_2*1000)
            //     }
            // } catch (error) {
            //     console.log(error);
            // }
        }
   },
   async mounted(){
        try {
            this.cargando = true
            this.msjeCargando = 'Cargando categorías...'
            if(!this.pantallaVista){
                let resultPantallaInicial = await ApiServer.getPantallaInicial(this.usuario.cod_cli)
                if(resultPantallaInicial.length>0){//MOSTRAR IMAGEN INICIAL
                    this.cargando = false
                    this.imagenInicial = `https://loguin.com.ar/img/imag_inicio/${resultPantallaInicial[0].imagen}?t=${(new Date()).getTime()}`;
                    this.dialogPantallaInicio = true
                    this.datosPantallInicio = resultPantallaInicial[0]
                    if(this.datosPantallInicio.visto==0){
                        this.pantallaInicialVista()
                        setTimeout(()=>{
                            this.dialogPantallaInicio=false
                        },this.datosPantallInicio.tiempo_1*1000)
                        await ApiServer.putPantallaInicial(this.usuario.cod_cli)//LO PONGO EN VISTO = 1
                        await ApiServer.postPantallaInicialLog({
                            cod_cli:this.usuario.cod_cli,
                            imagen:resultPantallaInicial[0].imagen,
                            visto:'S'
                        })
                    }else{
                        this.pantallaInicialVista()
                        setTimeout(()=>{
                            this.dialogPantallaInicio=false
                        },this.datosPantallInicio.tiempo_2*1000)
                    }
                }
            }
            this.ArtPadres = await ApiServer.getArtRubrosHijos('0',this.usuario.listas[0].LISTA_ID);
            this.ArtPadres.forEach(element => {
                element.imagen=`https://loguin.com.ar/img/rubros/${element.RUBRO_ID}.jpg`
            });
            this.cargando = false

            // CONSULTO SI HAY UN CLIENTE CARGADO
            if(this.usuario){
                let resultBotones = await ApiServer.getBotonesInferiores(this.usuario.cod_cli)
                this.botonesInferiores = resultBotones
                if( this.botonesInferiores.length>0){
                    let indexFav =  this.botonesInferiores.findIndex(x=>x.agrupador=="Favoritos")
                    if(indexFav>=0)
                    this.cantFavoritos = this.botonesInferiores[indexFav].cantidad

                    let indexOf =  this.botonesInferiores.findIndex(x=>x.agrupador=="Ofertas")
                    if(indexOf>=0)
                    this.cantOfertas = this.botonesInferiores[indexOf].cantidad

                    let indexSug =  this.botonesInferiores.findIndex(x=>x.agrupador=="Sugeridos")
                    if(indexSug>=0)
                    this.cantSugeridos = this.botonesInferiores[indexSug].cantidad
                }
            }
            
            

        } catch (error) {
            this.cargando = false
            console.log(error)
        }
    },
    computed:{
        ...mapState(['usuario','carrito','pantallaVista']),
        paltallaTelefono(){
            if(screen.width<=600){
                return true
            }else{
                return false
            }
        }
    }
}
</script>
<style scoped>
  .letra{
        font-family: sans-serif;
    }
    .btn-wsp{
        position:fixed;
        width:60px;
        height:60px;
        line-height: 63px;
        top:auto;
        bottom:700px;
        right:auto;
        background:#25d366;
        color:#FFF;
        border-radius:50px;
        text-align:center;
        font-size:35px;
        box-shadow: 0px 1px 10px rgba(0,0,0,0.3);
        z-index:1;
        transition: all 300ms ease;
    }
    .btn-wsp:hover{
        background: #20ba5a;
    }
    @media only screen and (min-width:320px) and (max-width:768px){
        .btn-wsp{
            width:63px;
            height:63px;
            line-height: 66px;
        }
    }
</style>
