import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@fortawesome/fontawesome-free/css/all.css'; // Importa Font Awesome CSS

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
		iconfont: 'fa', 
	}
});
